import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Strong, List, Box, Span, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaBalanceScale, FaWpforms, FaHandshake, FaCalculator, FaFileAlt, FaCoins, FaRegIdCard } from "react-icons/fa";
import { MdAssignmentTurnedIn, MdAccountBalance } from "react-icons/md";
import { TiCalculator } from "react-icons/ti";
import { IoMdPaper } from "react-icons/io";
import { GoLaw } from "react-icons/go";
import { GiBank } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"freelancers-jp"} />
		<Helmet>
			<title>
				Bos Balance：日本人のための会計事務所です。
			</title>
			<meta name={"description"} content={"日本語を話す窓口担当者が在籍しており、オランダの公認会計士と協力して運営しています。言語の壁を感じさせることなく、質の高い会計サービスを提供することを目的としています。会計関連業務についてスムーズに進めるためのサポートをいたしますので安心してお任せください。"} />
			<meta property={"og:title"} content={"Bos Balance：日本人のための会計事務所です。"} />
			<meta property={"og:description"} content={"日本語を話す窓口担当者が在籍しており、オランダの公認会計士と協力して運営しています。言語の壁を感じさせることなく、質の高い会計サービスを提供することを目的としています。会計関連業務についてスムーズに進めるためのサポートをいたしますので安心してお任せください。"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section
			padding="88px 0 88px 0"
			background="linear-gradient(0deg,--color-light 0%,--color-secondary 32.9%,rgba(0,0,0,0) 81%,rgba(255, 255, 255, 1) 100%),url(https://images.unsplash.com/photo-1528918387630-6b575e9fe5f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover repeat scroll padding-box"
			quarkly-title="Hero-25"
			min-height="400px"
			align-items="flex-end"
			justify-items="center"
		>
			<Override
				slot="SectionContent"
				lg-justify-content="center"
				flex-direction="column"
				flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
				md-grid-template-columns="1fr"
				sm-margin="0px 0 0px 0"
				sm-width="100%"
				align-items="center"
				display="flex"
				justify-content="center"
				position="relative"
			/>
			<Text
				margin="0px auto 0px auto"
				font="normal 900 40px/1.2 --fontFamily-googleOpenSans"
				as="h1"
				color="--light"
				text-align="center"
			>
				オランダ個人事業主（ZZP'er）向けの会計業務
			</Text>
		</Section>
		<Section background="--color-light" quarkly-title="Form">
			<Override slot="SectionContent" margin="0px auto 0px auto" sm-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="1fr 1fr"
				margin="50px 0px 0px 0px"
				md-grid-template-columns="1fr"
			>
				<Box
					lg-margin="0px 0px 40px 0px"
					display="grid"
					flex-direction="column"
					justify-content="flex-start"
					lg-width="100%"
					padding="36px 36px 36px 36px"
					margin="0px auto 0px auto"
					align-content="start"
					grid-gap="50px"
					md-padding="0 0 0 0"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2" color="--primary" sm-text-align="center">
						業務内容
					</Text>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="none"
						as="ul"
						display="grid"
						grid-gap="16px"
						grid-template-columns="1fr 10fr"
						align-items="center"
					>
						<Icon
							category="fa"
							icon={FaBalanceScale}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								一般会計業務
								<br />
							</Strong>
							正確で最新の財務データを管理します。
						</Text>
						<Icon
							category="md"
							icon={MdAssignmentTurnedIn}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								VAT申告
								<br />
							</Strong>
							VAT申告書を正確に作成し、期限内に提出します。
						</Text>
						<Icon
							category="ti"
							icon={TiCalculator}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								年末決算のまとめ
							</Strong>
							<br />
							貸借対照表、損益計算書を含む年末決算書を作成します。
						</Text>
						<Icon
							category="md"
							icon={MdAccountBalance}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								所得税申告
								<br />
							</Strong>
							個人事業主の所得税申告を代行します。
						</Text>
						<Icon
							category="fa"
							icon={FaWpforms}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								税務関連書類の記入
							</Strong>
							<br />
							税務署から届いたビジネス関連の書類をすべて記入します。
						</Text>
						<Icon
							category="fa"
							icon={FaHandshake}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								アドバイスとサポート
							</Strong>
							<br />
							専門的なアドバイスを行い、会計に関するご質問にお答えします。
						</Text>
					</List>
				</Box>
				<Box
					lg-margin="20px 0px 0px 0px"
					padding="36px 36px 48px 36px"
					background="--color-darkL2"
					md-padding="28px 20px 28px 20px"
					lg-width="100%"
					margin="0px auto 0px auto"
					id="form"
					box-shadow="--xl"
					border-radius="15px"
				>
					<Components.FreelancerFormJp reference="Japanese Freelancer">
						<Override slot="RequiredDescription">
							*は必須項目です。
						</Override>
						<Override slot="button">
							送信
						</Override>
					</Components.FreelancerFormJp>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="AccountCanAssistWith"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					会計上の要件や税務上の義務に関するサポート
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaBalanceScale}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								ビザ申請をサポートするオープニングバランスシートの作成
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCalculator}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								事業の正確な財務データを管理
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaFileAlt}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								VATの申告と支払い
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="io"
							icon={IoMdPaper}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								年度決算報告書の作成
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								オランダでの所得税の申告と支払い
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					display="block"
					border-radius="32px"
					max-height="600px"
					margin="0px auto 0px auto"
					srcSet="https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="--color-light">
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					オランダで個人事業主として登録を検討されている方へ
Bos Balanceは、居住許可を取得するためのお手伝いをいたします。必要な情報はこちらを参考にしてください。
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					個人事業主の条件
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="go"
							icon={GoLaw}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							日蘭通商条約に定められた特定の基準に加え、一般的な要件を満たす必要があります。
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="go"
							icon={GoLaw}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							要件には、オランダと日本との間の貿易に携わっていること、オランダでの事業活動を主導していること、日本の企業の代表として重要な地位に就いていることなどが含まれます。
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
				text-align="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					display="block"
					border-radius="32px"
					margin="0px auto 0px auto"
					max-height="450px"
					srcSet="https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					個人事業主の申請要件
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							申請手続きで、事業への資金を証明する必要があり、最低金額は€4,500です。
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					個人事業主の登録手続き
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaWpforms}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								商工会議所（KvK）： オランダ商工会議所（KvK）に事業を登録します。
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaWpforms}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								移民局(IND): 申請書を記入し、申請書提出期限内に提出する。申請書に記入し、オランダ到着後3ヶ月以内に提出する。承認されれば、許可証が発行され、居住許可証を受け取ることができます。
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaRegIdCard}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								地方自治体（BSN）： 国民識別番号を取得します。Burgerservicenummer (BSN)は様々な行政手続きに必要不可欠です。
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="gi"
							icon={GiBank}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							銀行口座開設 最低額€4,500でオランダのビジネス銀行口座を開設し、金額を維持する必要があります。
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					個人事業主の申請手順
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaBalanceScale}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							要件の確認： 申請資格を満たしていることを確認し、必要書類を揃える。
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCalculator}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							申請書の提出： 申請書に記入し、オランダ到着後3ヶ月以内に提出する。
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaFileAlt}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							申請料の支払い： 申請料は€380。
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="io"
							icon={IoMdPaper}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							承認待ち： 移民局（IND）は90日以内に申請書を処理しますが、場合によっては延長の可能性もあります。
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							ビザ交付：承認されると、滞在許可証を受け取ることができます。
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="#fafaf9">
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					Bos Balanceはオランダに拠点を置き、日本語を話す窓口担当者が在籍しており、オランダの公認会計士と協力して運営しています。オランダに住む日本人の皆様に対し、言語の壁を感じさせることなく、質の高い会計サービスを提供することを目的としています。
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				text-align="center"
				align-items="center"
				display="grid"
				justify-items="center"
			>
				<Button
					href="#form"
					background="--color-utOrange"
					padding="8px 24px 8px 30p"
					color="--light"
					font="--lead"
					hover-transform="scale(1.1)"
					active-background="--color-secondary"
					transition="all 0.5s ease 0s"
					focus-box-shadow="unset"
					focus-background="--color-secondary"
					border-radius="5px"
					box-shadow="--l"
					type="link"
					text-decoration-line="initial"
				>
					お問い合わせ
				</Button>
			</Box>
		</Section>
		<Section background="#fafaf9">
			<Button
				background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/ukflag.png?v=2024-06-08T16:00:54.622Z) 0% 0%/contain no-repeat"
				font="--base"
				top="596px"
				bottom="4999px"
				color="--secondary"
				width="max-content"
				padding="8px 24px 8px 73px"
				box-shadow="--xxl"
				border-radius="15px"
				hover-box-shadow="none"
				transition="all 0.5s ease 0s"
				opacity="0.8"
				hover-opacity="1"
				type="link"
				text-decoration-line="initial"
				href="/freelancers"
			>
				&lt;&lt; Read it in English
			</Button>
		</Section>
		<Components.Footer />
		<Button
			type="link"
			text-decoration-line="initial"
			position="fixed"
			right={0}
			bottom={0}
			margin="0px 5px 5px 0px"
			background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/WhatsAppButtonGreenMedium.svg?v=2024-06-08T12:53:12.802Z) 0% 0%/contain no-repeat"
			width="192px"
			height="42px"
			box-shadow="--xl"
			href="https://wa.me/31616555767?text=%E3%81%8A%E4%B8%96%E8%A9%B1%E3%81%AB%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%99%E3%80%82%E8%B2%B4%E7%A4%BE%E3%81%AE%E4%BC%9A%E8%A8%88%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E3%80%81%E8%B3%AA%E5%95%8F%E3%81%8C%E3%81%94%E3%81%96%E3%81%84%E3%81%BE%E3%81%99%E3%80%82"
			opacity="0.9"
			transition="all 0.5s ease 0s"
			hover-transform="rotate(3deg)"
		/>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});